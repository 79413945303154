import { jwtDecode } from 'jwt-decode'

import { voyagerApiMeUrl } from '$extensionSrc/automations/linkedin/linkedinUrls'
import {
  loginUrl,
  graphqlUrl as stravaGraphqlUrl,
  profileUrl as stravaProfileUrl,
} from '$extensionSrc/automations/strava/stravaUrls'
import { profileUrl as tiktokProfileUrl } from '$extensionSrc/automations/tiktok/tiktokUrls'

import * as cookies from './cookies'
import { PLATFORM, STORAGE_KEY } from './enums'
import { getSingle } from './localStorage'
import { logInfo } from './loggingUtils'
import { isHostPermissionPresent } from './permissions'
import BuildConfig from '../buildConfig'
import { _debug } from './logging'

/**
 * The c_user cookie is the user's primary account; if the user
 * switches accounts (e.g., to go to their page), then the i_user
 * cookie will be present.
 */
export async function getFacebookLogin(
  storeId: string,
): Promise<string | null> {
  const iUserCookie = await cookies.getCookie({
    name: 'i_user',
    storeId,
    url: 'https://www.facebook.com',
  })

  if (iUserCookie) {
    return iUserCookie.value
  }

  const cUserCookie = await cookies.getCookie({
    name: 'c_user',
    storeId,
    url: 'https://www.facebook.com',
  })
  return cUserCookie ? cUserCookie.value : null
}

export async function getGoogleLogin(storeId: string): Promise<string | null> {
  const isLoggedInCookie = await cookies.getCookie({
    name: 'SSID',
    storeId,
    url: 'https://accounts.google.com',
  })
  const hasPermission = await isHostPermissionPresent('https://www.google.com')
  if (isLoggedInCookie && hasPermission) {
    try {
      const response = await fetch('https://www.google.com')
      if (!response?.ok) {
        return null
      }
      const blob = await response.blob()
      const text = await blob.text()
      // Use the first email address in a div with no class names on the Google
      // homepage
      const pattern =
        /<div>(\b[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}\b)<\/div>/im
      const match = pattern.exec(text)
      if (!match) {
        return null
      }
      return match[1]
    } catch (error) {
      // No consequence. Login will not be detected either way.
    }
  }
  return null
}

/**
 * The ds_user_id cookie indicates their Instagram ID, though the name is
 * suspicious given that it's not prefixed with "ig_". If this stops working,
 * consider trying the LinkedIn strategy (fetch homepage, and try to scrape
 * the ID).
 */
export async function getInstagramLogin(
  storeId: string,
): Promise<string | null> {
  const [userIdCookie, sessionIdCookie] = await Promise.all([
    cookies.getCookie({
      name: 'ds_user_id',
      storeId,
      url: 'https://www.instagram.com',
    }),
    cookies.getCookie({
      name: 'sessionid',
      storeId,
      url: 'https://www.instagram.com',
    }),
  ])

  if (userIdCookie && sessionIdCookie) {
    return userIdCookie.value
  }

  return null
}

export async function getLinkedinUseridFromVoyagerApi(): Promise<string> {
  const csrfCookie = await cookies.getCookie({
    name: 'JSESSIONID',
    url: 'https://www.linkedin.com',
  })

  if (!csrfCookie || !csrfCookie.value) {
    throw new Error('Could not find JSESSIONID cookie for csrf token')
  }

  const csrfToken = csrfCookie.value.replaceAll('"', '')
  const response = await fetch(voyagerApiMeUrl, {
    method: 'GET',
    headers: { 'CSRF-TOKEN': csrfToken },
  })

  if (!response.ok) {
    throw new Error(
      `Bad response from Voyager API's /me endpoint (${response.status} - ${response.statusText})`,
    )
  }

  const body = await response.json()

  if (!body?.plainId) {
    const keysAsStr = Object.keys(body || {}).join(',')
    throw new Error(
      `Data not found in Voyager API's response (has keys ${keysAsStr})`,
    )
  }

  _debug(
    `Found user id ${body?.plainId} from linkedin voyager api`,
    'platform user data',
  )

  // Return cast to string, but only if it's truthy
  return body.plainId ? String(body.plainId) : body.plainId
}

export async function getLinkedinUseridFromHomepage(): Promise<string> {
  let response = await fetch('https://www.linkedin.com/')

  if (!response.ok) {
    response = await fetch('https://www.linkedin.com/')
  }

  if (!response.ok) {
    response = await fetch('https://www.linkedin.com/')
  }

  if (!response.ok) {
    throw new Error(
      `Bad response querying homepage (${response?.status} - ${response?.statusText})`,
    )
  }

  const homepageContent = await response.blob().then((blob) => blob?.text())
  if (!homepageContent) {
    throw new Error(`No body found in response`)
  }

  const pattern = /urn:li:member:([\w-.]+)/
  const match = pattern.exec(homepageContent)
  if (!match) {
    throw new Error('Unable to find user id in homepage response')
  }

  _debug(
    `Found user id ${match?.[1]} from linkedin homepage`,
    'platform user data',
  )

  return match[1]
}

export async function getLinkedinLogin(
  storeId: string,
): Promise<string | null> {
  // li_at is present if and only if user is logged in;
  // unfortunately does not tell us their user id
  const liAtCookie = await cookies.getCookie({
    name: 'li_at',
    storeId,
    url: 'https://www.linkedin.com',
  })

  if (!liAtCookie) {
    return null
  }

  if (await isHostPermissionPresent('https://www.linkedin.com/')) {
    try {
      return await getLinkedinUseridFromVoyagerApi()
    } catch (voyagerError) {
      logInfo(
        `Failed to get user ID from Voyager API (error: ${voyagerError.message})`,
        'getLinkedinLogin',
      )
    }

    try {
      return await getLinkedinUseridFromHomepage()
    } catch (homepageError) {
      logInfo(
        `Failed to get user ID from homepage (error: ${homepageError.message})`,
        'getLinkedinLogin',
      )
    }

    return undefined
  }

  return null
}

/**
 * Reddit stores the user ID in the `aid` claim of a JWT stored in the
 * token_v2 cookie OR a component of the loid cookie.
 */
export async function getRedditLogin(storeId: string): Promise<string | null> {
  // This cookie doesn't store the user ID, but it does indicate if a user is
  // logged in or not. The token_v2 and loid cookies are not cleared on logout.
  const redditSessionCookie = await cookies.getCookie({
    name: 'reddit_session',
    storeId,
    url: 'https://www.reddit.com',
  })
  if (!redditSessionCookie?.value) {
    return null
  }
  // This cookie will have the Reddit user ID if the user has not opted out of
  // "new Reddit." This is the default, so most people will have their login
  // detected by this.
  const tokenV2Cookie = await cookies.getCookie({
    name: 'token_v2',
    storeId,
    url: 'https://www.reddit.com',
  })
  if (tokenV2Cookie?.value) {
    const payload = jwtDecode<{ aid: string }>(tokenV2Cookie.value)
    if (payload?.aid) {
      return payload.aid
    }
  }
  // If the user has "old Reddit" enabled, the Reddit user ID is in a cookie
  // named loid.
  const loidCookie = await cookies.getCookie({
    name: 'loid',
    storeId,
    url: 'https://www.reddit.com',
  })
  if (!loidCookie?.value) {
    return null
  }
  // This cookie is a string seemingly delineated with '.' characters
  const loidComponents = loidCookie.value.split('.')
  if (loidComponents.length < 1) {
    return null
  }
  const id = loidComponents[0]
  // The first item in the loid contains the user ID, prefixed with a bunch of
  // zeros. We have to prefix 't2_' here, since the user IDs in token_v2 have
  // that, and we want existing extension users to have their accounts match
  // up.
  return `t2_${id.replace(/^0+/, '')}`
}

/**
 * The twid cookie indicates user's account id; has format
 * "u%3D12345" which is the URI encoded value of "u=12345".
 * We URI decode the value & strip the "u=" prefix.
 */
export async function getTwitterLogin(storeId: string): Promise<string | null> {
  const twidCookie = await cookies.getCookie({
    name: 'twid',
    storeId,
    url: 'https://www.x.com',
  })

  if (!twidCookie || !twidCookie.value) {
    return null
  }

  const alphanumericPat = /u=([a-zA-Z0-9]+)/
  const decodedVal = decodeURIComponent(twidCookie.value)
  const match = alphanumericPat.exec(decodedVal)

  if (!match) {
    return null
  }

  return match[1]
}

/**
 *
 * The v_id cookie indicates the user's accoount; however, the v_id
 * cookie persists even after logout, so we check instead for the
 * api_access_token which is added / removed by login / logout.
 */
export async function getVenmoLogin(storeId: string): Promise<string | null> {
  const apiCookie = await cookies.getCookie({
    name: 'api_access_token',
    storeId,
    url: 'https://www.venmo.com',
  })

  if (!apiCookie) {
    return null
  }

  // check if cookie is expired - only on Firefox
  if (
    BuildConfig.BROWSER === 'firefox' &&
    apiCookie.expirationDate &&
    apiCookie.expirationDate * 1000 < Date.now()
  ) {
    return null
  }

  const vIdCookie = await cookies.getCookie({
    name: 'v_id',
    storeId,
    url: 'https://www.venmo.com',
  })
  return vIdCookie ? vIdCookie.value : null
}

export async function getStravaLoginFromProfileUrlRedirect(): Promise<
  string | null
> {
  const response = await fetch(stravaProfileUrl, { method: 'HEAD' })

  if (!response.ok) {
    throw new Error('Request to profile url failed')
  }

  if (response.url === loginUrl) {
    return null
  }

  const userId = response.url.split('/').reverse()[0]

  _debug(
    `Found user id ${userId} from strava profile url redirect`,
    'platform user data',
  )

  return userId
}

/**
 * Query the Strava GraphQL API to get the user ID. If the user is logged out,
 * it should still succeed; it will return an ID of "null".
 */
export async function getStravaLoginFromGraphql(): Promise<string | null> {
  const response = await fetch(stravaGraphqlUrl, {
    body: JSON.stringify({
      query: 'query Me { me {id firstName lastName} }',
      variables: {},
      operationName: 'Me',
    }),
    method: 'POST',
    credentials: 'include',
    headers: { 'Content-Type': 'application/json' },
  })

  if (!response.ok) {
    throw new Error(
      `Request to Strava GQL failed (${response.status} - ${response.statusText})`,
    )
  }

  const json = await response.json()

  const userId = json?.data?.me?.id
  if (userId === undefined) {
    throw new Error('Failed to parse Strava GQL response')
  }

  _debug(`Found user id ${userId} from strava graphql`, 'platform user data')

  return userId
}

export async function getStravaLogin(): Promise<string | null> {
  if (await isHostPermissionPresent('https://www.strava.com/')) {
    try {
      return await getStravaLoginFromGraphql()
    } catch (error) {
      logInfo(
        `Failed to get user ID from Strava GQL (error: ${error.message})`,
        'getStravaLogin',
      )
    }

    try {
      return await getStravaLoginFromProfileUrlRedirect()
    } catch (error) {
      logInfo(
        `Failed to get user ID from profile url redirect (error: ${error.message})`,
        'getStravaLogin',
      )
    }
  }

  return null
}

export async function getTiktokLogin() {
  let userId: string | null = null

  if (await isHostPermissionPresent('https://www.tiktok.com/')) {
    const response = await fetch(tiktokProfileUrl)

    if (response && response.ok) {
      const parsedUserId = response.url.split('/').reverse()[0].split('?')[0]

      // Logged out users are redirected to a "for you" feed
      if (parsedUserId !== 'foryou') {
        userId = parsedUserId
      }
    }
  }

  return userId
}

export async function getYoutubeLogin(storeId: string) {
  const loginInfoCookie = await cookies.getCookie({
    name: 'LOGIN_INFO',
    storeId,
    url: 'https://www.youtube.com',
  })

  if (
    loginInfoCookie &&
    (await isHostPermissionPresent('https://www.youtube.com/account'))
  ) {
    try {
      const infoPageContent = await fetch('https://www.youtube.com/account')
        .then((response) => response?.blob())
        .then((blob) => blob?.text())

      if (infoPageContent) {
        const pattern = /(,{"text":"(.+?)"}]},"pageTitle")/
        const match = pattern.exec(infoPageContent)
        return match?.[2] || null
      }
    } catch (error) {
      // No consequence. Login will not be detected either way.
    }
  }

  return null
}

/**
 * Helper used in background script cookie listener that looks for changes in
 * cookies so that we can proactively update login status (updates an object
 * in local storage so that those login status changes are propagated throughout
 * the extension).
 */
export function getPlatformFromCookie(
  cookie: chrome.cookies.Cookie,
): PLATFORM | null {
  // We use a "Record" type so that whenever we add a new platform,
  // typescript reminds us to update this mapping.
  const mapPlatformToPredicate: Record<
    PLATFORM,
    (c: chrome.cookies.Cookie) => boolean
  > = {
    [PLATFORM.FACEBOOK]: (c) =>
      c.domain.includes('facebook.com') &&
      (c.name === 'c_user' || c.name === 'i_user'),
    [PLATFORM.GOOGLE]: (c) =>
      c.domain.includes('google.com') && c.name === 'SSID',
    [PLATFORM.INSTAGRAM]: (c) =>
      c.domain.includes('instagram.com') && c.name === 'ds_user_id',
    [PLATFORM.LINKEDIN]: (c) =>
      c.domain.includes('linkedin.com') && c.name === 'li_at',
    [PLATFORM.REDDIT]: (c) =>
      c.domain.includes('reddit.com') && c.name === 'token_v2',
    [PLATFORM.SNAPCHAT]: () => false, // never match; no login detection for Snapchat
    [PLATFORM.STRAVA]: () => false, // never match; we don't use cookies for Strava
    [PLATFORM.TIKTOK]: () => false, // never match; no login detection for TikTok
    [PLATFORM.TWITTER]: (c) => c.domain.includes('.x.com') && c.name === 'twid',
    [PLATFORM.VENMO]: (c) =>
      c.domain.includes('venmo.com') &&
      (c.name === 'api_access_token' || c.name === 'v_id'),
    [PLATFORM.YOUTUBE]: (c) =>
      c.domain.includes('youtube.com') && c.name === 'LOGIN_INFO',
  }

  for (const [platform, predicate] of Object.entries(mapPlatformToPredicate)) {
    if (predicate(cookie)) {
      return PLATFORM[platform]
    }
  }

  return null
}

type LoginGetter = (cookieStoreId: string) => Promise<string | null | undefined>

/**
 * Helper method that
 */
export function getLoginGetter(platform: PLATFORM): LoginGetter {
  // We use a "Record" type so that whenever we add a new platform,
  // typescript reminds us to update this mapping.
  const mapToMethod: Record<PLATFORM, LoginGetter> = {
    [PLATFORM.FACEBOOK]: getFacebookLogin,
    [PLATFORM.GOOGLE]: getGoogleLogin,
    [PLATFORM.INSTAGRAM]: getInstagramLogin,
    [PLATFORM.REDDIT]: getRedditLogin,
    [PLATFORM.LINKEDIN]: getLinkedinLogin,
    [PLATFORM.SNAPCHAT]: async () => null, // no login detection for Snapchat
    [PLATFORM.STRAVA]: getStravaLogin,
    [PLATFORM.TIKTOK]: async () => null, // unused right now
    [PLATFORM.TWITTER]: getTwitterLogin,
    [PLATFORM.VENMO]: getVenmoLogin,
    [PLATFORM.YOUTUBE]: getYoutubeLogin,
  }

  return mapToMethod[platform]
}

/**
 * Returns the user id in the login status storage object if exists. Returns null if
 * no evidence that user is logged in.
 */
export function getLoginStatusFromStorageBlob(
  blob: any,
  cookieStoreId: string,
  platform: PLATFORM,
): string | null {
  return blob?.[cookieStoreId]?.[platform] || null
}

/**
 * Returns the user id in the login status storage object if exists. Returns null if
 * no evidence that user is logged in.
 */
export async function getLoginStatus(
  cookieStoreId: string,
  platform: PLATFORM,
): Promise<string | null> {
  const blob = (await getSingle(STORAGE_KEY.LOGIN_STATUS)) || {}
  return getLoginStatusFromStorageBlob(blob, cookieStoreId, platform)
}

export async function setLoginStatus(
  cookieStoreId: string,
  platform: PLATFORM,
  userId: string,
) {
  const blob = (await getSingle(STORAGE_KEY.LOGIN_STATUS)) || {}

  if (!blob[cookieStoreId]) {
    blob[cookieStoreId] = {}
  }

  blob[cookieStoreId][platform] = userId

  return chrome.storage.local.set({ [STORAGE_KEY.LOGIN_STATUS]: blob })
}

export async function getAndSetLoginStatus(
  platform: PLATFORM,
  cookieStoreId: string,
): Promise<boolean> {
  const loginGetter = getLoginGetter(platform)
  const userId = await loginGetter(cookieStoreId)

  if (userId !== undefined) {
    await setLoginStatus(cookieStoreId, platform, userId)
    return true
  }

  return false
}

/**
 * Platforms that are mobile only do not have login detection or platform user data
 */
const MOBILE_ONLY_PLATFORMS = new Set([PLATFORM.SNAPCHAT, PLATFORM.TIKTOK])
export function isMobileOnlyPlatform(platform: PLATFORM): boolean {
  return MOBILE_ONLY_PLATFORMS.has(platform)
}
