export type SettingKey =
  | StravaSettingKey
  | VenmoSettingKey
  | XSettingKey
  | LinkedinSettingKey
  | InstagramSettingKey
  | FacebookSettingKey
  | RedditSettingKey
  | YoutubeSettingKey
  | GoogleSettingKey

export enum StravaSettingKey {
  ACTIVITIES_VISIBILITY = 'ACTIVITIES_VISIBILITY',
  FLYBY_VISIBILITY = 'FLYBY_VISIBILITY',
  FOLLOWER_NOTIFICATIONS = 'FOLLOWER_NOTIFICATIONS',
  GROUP_ACTIVITIES_VISIBILITY = 'GROUP_ACTIVITIES_VISIBILITY',
  LOCAL_LEGENDS_VISIBILITY = 'LOCAL_LEGENDS_VISIBILITY',
  MAP_VISIBILITY = 'MAP_VISIBILITY',
  MENTIONS_SETTING = 'MENTIONS_SETTING',
  PAST_ACTIVITIES_PRIVACY = 'PAST_ACTIVITIES_PRIVACY',
  PROFILE_VISIBILITY = 'PROFILE_VISIBILITY',
}

export enum VenmoSettingKey {
  PAYMENT_PRIVACY_FUTURE_PAYMENTS = 'PAYMENT_PRIVACY_FUTURE_PAYMENTS',
  PAYMENT_PRIVACY_PAST_PAYMENTS = 'PAYMENT_PRIVACY_PAST_PAYMENTS',
  AUTOMATIC_FRIENDING_PHONE_CONTACTS = 'AUTOMATIC_FRIENDING_PHONE_CONTACTS',
  NOTIFICATION_PAYMENT_REQUEST = 'NOTIFICATION_PAYMENT_REQUEST',
  NOTIFICATION_PAYMENT_RECEIVED = 'NOTIFICATION_PAYMENT_RECEIVED',
  NOTIFICATION_PAYMENT_SENT = 'NOTIFICATION_PAYMENT_SENT',
  NOTIFICATION_ACTIVITY_MENTIONS = 'NOTIFICATION_ACTIVITY_MENTIONS',
  NOTIFICATION_COMMENTS_ON_PAYMENTS = 'NOTIFICATION_COMMENTS_ON_PAYMENTS',
  NOTIFICATION_LIKES_ON_PAYMENTS = 'NOTIFICATION_LIKES_ON_PAYMENTS',
  NOTIFICATION_MENTIONS_OF_YOU = 'NOTIFICATION_MENTIONS_OF_YOU',
  NOTIFICATION_VENMO_PURCHASES = 'NOTIFICATION_VENMO_PURCHASES',
  NOTIFICATION_VENMO_UPDATES = 'NOTIFICATION_VENMO_UPDATES',
}

export enum XSettingKey {
  AI_OPT_OUT = 'AI_OPT_OUT',
  BLOCK_REPOSTERS = 'BLOCK_REPOSTERS',
  DELETE_POSTS_BEFORE = 'DELETE_POSTS_BEFORE',
  LEAVE_DIRECT_MESSAGES = 'LEAVE_DIRECT_MESSAGES',
  MUTED_DONT_FOLLOW = 'MUTED_DONT_FOLLOW',
  MUTED_DONT_FOLLOW_YOU = 'MUTED_DONT_FOLLOW_YOU',
  MUTED_NEW_ACCOUNT = 'MUTED_NEW_ACCOUNT',
  MUTED_DEFAULT_PROFILE = 'MUTED_DEFAULT_PROFILE',
  MUTED_UNCONFIRMED_EMAIL = 'MUTED_UNCONFIRMED_EMAIL',
  MUTED_UNCONFIRMED_PHONE = 'MUTED_UNCONFIRMED_PHONE',
  PROFILE_REMOVE_LOCATION = 'PROFILE_REMOVE_LOCATION',
  REMOVE_LIKES = 'REMOVE_LIKES',
  TWEET_LOCATION_EXISTING = 'TWEET_LOCATION_EXISTING',
  TWEET_LOCATION_FUTURE = 'TWEET_LOCATION_FUTURE',
  DISCOVER_EMAIL_ADDRESS = 'DISCOVER_EMAIL_ADDRESS',
  DISCOVER_PHONE_NUMBER = 'DISCOVER_PHONE_NUMBER',
  LOGIN_VERIFICATION = 'LOGIN_VERIFICATION',
}

export enum LinkedinSettingKey {
  LOCATION_VISIBILITY = 'LOCATION_VISIBILITY',
  EMAIL_VISIBILITY = 'EMAIL_VISIBILITY',
  EMAIL_EXPORT = 'EMAIL_EXPORT',
  CONNECTIONS_LIST = 'CONNECTIONS_LIST',
  REPRESENT_COMPANY = 'REPRESENT_COMPANY',
  EMAIL_DISCOVERABILITY = 'EMAIL_DISCOVERABILITY',
  PHONE_DISCOVERABILITY = 'PHONE_DISCOVERABILITY',
  STATUS_VISIBILITY = 'STATUS_VISIBILITY',
  TWO_STEP_VERIFICATION = 'TWO_STEP_VERIFICATION',
  // LINKEDIN_NOTIFICATION_PREFERENCES
  JOB_SEARCH = 'JOB_SEARCH',
  HIRING_SOMEONE = 'HIRING_SOMEONE',
  CONNECTING_WITH_OTHERS = 'CONNECTING_WITH_OTHERS',
  POSTING_COMMENTING = 'POSTING_COMMENTING',
  MESSAGING = 'MESSAGING',
  GROUPS = 'GROUPS',
  PAGES = 'PAGES',
  EVENTS = 'EVENTS',
  NEWS = 'NEWS',
  REPORTS = 'REPORTS',
  UPDATING_YOUR_PROFILE = 'UPDATING_YOUR_PROFILE',
  VERIFICATIONS = 'VERIFICATIONS',
  // End notification preferences
  GENERATIVE_AI_TRAINING = 'GENERATIVE_AI_TRAINING',
  SOCIAL_ECONOMIC_RESEARCH = 'SOCIAL_ECONOMIC_RESEARCH',
}

export enum InstagramSettingKey {
  MANUALLY_APPROVE_TAGS = 'MANUALLY_APPROVE_TAGS',
  WHO_CAN_TAG_YOU = 'WHO_CAN_TAG_YOU',
  EMAIL_NOTIFICATION_FEEDBACK = 'EMAIL_NOTIFICATION_FEEDBACK',
  EMAIL_NOTIFICATION_REMINDER = 'EMAIL_NOTIFICATION_REMINDER',
  EMAIL_NOTIFICATION_PRODUCT = 'EMAIL_NOTIFICATION_PRODUCT',
  EMAIL_NOTIFICATION_NEWS = 'EMAIL_NOTIFICATION_NEWS',
  EMAIL_NOTIFICATION_ABOUT_SHOPS = 'EMAIL_NOTIFICATION_ABOUT_SHOPS',
  EMAIL_NOTIFICATION_CART = 'EMAIL_NOTIFICATION_CART',
  EMAIL_NOTIFICATION_PROMOTIONAL = 'EMAIL_NOTIFICATION_PROMOTIONAL',
  HIDE_COMMENTS = 'HIDE_COMMENTS',
  HIDE_MESSAGES = 'HIDE_MESSAGES',
  PRIVATE_ACCOUNT = 'PRIVATE_ACCOUNT',
  PUSH_NOTIFICATION_LIKES = 'PUSH_NOTIFICATION_LIKES',
  PUSH_NOTIFICATION_LIKES_AND_COMMENTS_ON_PHOTOS_OF_YOU = 'PUSH_NOTIFICATION_LIKES_AND_COMMENTS_ON_PHOTOS_OF_YOU',
  PUSH_NOTIFICATION_COMMENTS = 'PUSH_NOTIFICATION_COMMENTS',
  PUSH_NOTIFICATION_COMMENT_LIKES = 'PUSH_NOTIFICATION_COMMENT_LIKES',
  PUSH_NOTIFICATION_COMMENT_DAILY_DIGEST = 'PUSH_NOTIFICATION_COMMENT_DAILY_DIGEST',
  PUSH_NOTIFICATION_FIRST_POSTS_AND_STORIES = 'PUSH_NOTIFICATION_FIRST_POSTS_AND_STORIES',
  PUSH_NOTIFICATION_ACCOUNT_SUGGESTIONS = 'PUSH_NOTIFICATION_ACCOUNT_SUGGESTIONS',
  PUSH_NOTIFICATION_MENTIONS_IN_BIO = 'PUSH_NOTIFICATION_MENTIONS_IN_BIO',
  PUSH_NOTIFICATION_MESSAGE_REQUESTS = 'PUSH_NOTIFICATION_MESSAGE_REQUESTS',
  PUSH_NOTIFICATION_GROUP_REQUESTS = 'PUSH_NOTIFICATION_GROUP_REQUESTS',
  PUSH_NOTIFICATION_BROADCAST_CHANNEL_INVITES = 'PUSH_NOTIFICATION_BROADCAST_CHANNEL_INVITES',
  PUSH_NOTIFICATION_BROADCAST_CHANNEL_MESSAGES = 'PUSH_NOTIFICATION_BROADCAST_CHANNEL_MESSAGES',
  PUSH_NOTIFICATION_VIDEO_CHATS = 'PUSH_NOTIFICATION_VIDEO_CHATS',
  PUSH_NOTIFICATION_ORIGINAL_AUDIO = 'PUSH_NOTIFICATION_ORIGINAL_AUDIO',
  PUSH_NOTIFICATION_LIVE_VIDEOS = 'PUSH_NOTIFICATION_LIVE_VIDEOS',
  PUSH_NOTIFICATION_RECENTLY_UPLOADED_REELS = 'PUSH_NOTIFICATION_RECENTLY_UPLOADED_REELS',
  PUSH_NOTIFICATION_MOST_WATCHED_REELS = 'PUSH_NOTIFICATION_MOST_WATCHED_REELS',
  PUSH_NOTIFICATION_ADD_YOURS_PROMPTS = 'PUSH_NOTIFICATION_ADD_YOURS_PROMPTS',
  PUSH_NOTIFICATION_FUNDRAISERS_BY_OTHERS = 'PUSH_NOTIFICATION_FUNDRAISERS_BY_OTHERS',
  PUSH_NOTIFICATION_NOTIFICATION_REMINDERS = 'PUSH_NOTIFICATION_NOTIFICATION_REMINDERS',
  PUSH_NOTIFICATION_PRODUCT_ANNOUNCEMENTS_AND_FEEDBACK = 'PUSH_NOTIFICATION_PRODUCT_ANNOUNCEMENTS_AND_FEEDBACK',
  PUSH_NOTIFICATION_TRENDING_PLACES = 'PUSH_NOTIFICATION_TRENDING_PLACES',
  PUSH_NOTIFICATION_BIRTHDAYS = 'PUSH_NOTIFICATION_BIRTHDAYS',
  STATUS_VISIBILITY = 'STATUS_VISIBILITY',
  WHO_CAN_REPLY_TO_STORIES = 'WHO_CAN_REPLY_TO_STORIES',
  ALLOW_COMMENTS_FROM = 'ALLOW_COMMENTS_FROM',
  SETUP_TWO_FACTOR_AUTHENTICATION = 'SETUP_TWO_FACTOR_AUTHENTICATION',
}

export enum FacebookSettingKey {
  ABOUT_EMAIL = 'ABOUT_EMAIL',
  ABOUT_PHONE_NUMBER = 'ABOUT_PHONE_NUMBER',
  ABOUT_CURRENT_CITY = 'ABOUT_CURRENT_CITY',
  ABOUT_HOMETOWN = 'ABOUT_HOMETOWN',
  ABOUT_BIRTH_DATE = 'ABOUT_BIRTH_DATE',
  ABOUT_BIRTH_YEAR = 'ABOUT_BIRTH_YEAR',
  ABOUT_WORK_EDUCATION = 'ABOUT_WORK_EDUCATION',
  ABOUT_FAMILY = 'ABOUT_FAMILY',
  ABOUT_RELATIONSHIPS = 'ABOUT_RELATIONSHIPS',
  VISIBILITY_FUTURE_POSTS = 'VISIBILITY_FUTURE_POSTS',
  VISIBILITY_PAST_POSTS = 'VISIBILITY_PAST_POSTS',
  WHO_CAN_CONNECT_FRIEND_REQUESTS = 'WHO_CAN_CONNECT_FRIEND_REQUESTS',
  WHO_CAN_CONNECT_FRIENDS_LIST = 'WHO_CAN_CONNECT_FRIENDS_LIST',
  REVIEW_POSTS = 'REVIEW_POSTS',
  REVIEW_TAGS = 'REVIEW_TAGS',
  TAGGED_VISIBILITY = 'TAGGED_VISIBILITY',
  CONNECTED_APPS = 'CONNECTED_APPS',
  PROFILE_PHOTO_PRIVACY = 'PROFILE_PHOTO_PRIVACY',
  COVER_PHOTO_PRIVACY = 'COVER_PHOTO_PRIVACY',
  CURRENT_COVER_PHOTO = 'CURRENT_COVER_PHOTO',
  ALBUM_PRIVACY = 'ALBUM_PRIVACY',
  PHOTO_UNTAGGING = 'PHOTO_UNTAGGING',
  SETUP_2FA = 'SETUP_2FA',
  PROFILE_EMAIL_DISCOVERABILITY = 'PROFILE_EMAIL_DISCOVERABILITY',
  PROFILE_PHONE_DISCOVERABILITY = 'PROFILE_PHONE_DISCOVERABILITY',
  SEARCH_ENGINE_LINKING = 'SEARCH_ENGINE_LINKING',
  PHONE_NUMBER_MESSAGE_REQUESTS = 'PHONE_NUMBER_MESSAGE_REQUESTS',
  FRIENDS_OF_FRIENDS_MESSAGE_REQUESTS = 'FRIENDS_OF_FRIENDS_MESSAGE_REQUESTS',
  OTHER_MESSAGE_REQUESTS = 'OTHER_MESSAGE_REQUESTS',
  WHO_CAN_FOLLOW = 'WHO_CAN_FOLLOW',
  WHO_CAN_SEE_FOLLOWERS = 'WHO_CAN_SEE_FOLLOWERS',
  WHO_CAN_SEE_YOUR_FOLLOWS = 'WHO_CAN_SEE_YOUR_FOLLOWS',
  WHO_CAN_COMMENT_PUBLIC_POSTS = 'WHO_CAN_COMMENT_PUBLIC_POSTS',
  PUBLIC_POST_NOTIF = 'PUBLIC_POST_NOTIF',
  PUBLIC_PROFILE_INFO = 'PUBLIC_PROFILE_INFO',
  RELEVANT_COMMENTS = 'RELEVANT_COMMENTS',
  OFF_FACEBOOK_PREVIEWS = 'OFF_FACEBOOK_PREVIEWS',
  WHO_CAN_SEE_POSTS = 'WHO_CAN_SEE_POSTS',
  POST_SHARING = 'POST_SHARING',
  COMMENT_NOTIFICATIONS = 'COMMENT_NOTIFICATIONS',
  TAGS_THAT_NOTIFY = 'TAGS_THAT_NOTIFY',
  TAG_NOTIFIFICATIONS = 'TAG_NOTIFIFICATIONS',
  BATCH_MENTIONS = 'BATCH_MENTIONS',
  COMMENTED_ON_POSTS = 'COMMENTED_ON_POSTS',
  JOINED_GROUPS = 'JOINED_GROUPS',
  MENTIONED_PEOPLE = 'MENTIONED_PEOPLE',
  REMINDER_NOTIFICATIONS = 'REMINDER_NOTIFICATIONS',
  ACTIVITY_ABOUT_YOU = 'ACTIVITY_ABOUT_YOU',
  FRIENDS_UPDATES = 'FRIENDS_UPDATES',
  FRIEND_REQUEST_NOTIFICATIONS = 'FRIEND_REQUEST_NOTIFICATIONS',
  PEOPLE_YOU_MAY_KNOW = 'PEOPLE_YOU_MAY_KNOW',
  BIRTHDAY_NOTIFICATIONS = 'BIRTHDAY_NOTIFICATIONS',
  GROUP_NOTIFICATIONS = 'GROUP_NOTIFICATIONS',
  VIDEO_NOTIFICATIONS = 'VIDEO_NOTIFICATIONS',
  EVENTS_NOTIFICATIONS = 'EVENTS_NOTIFICATIONS',
  FOLLOWED_PAGES_NOTIFICATIONS = 'FOLLOWED_PAGES_NOTIFICATIONS',
  OTHER_NOTIFICATIONS = 'OTHER_NOTIFICATIONS',
  BROWSER_SOUNDS = 'BROWSER_SOUNDS',
  EMAIL_FREQUENCY = 'EMAIL_FREQUENCY',
  UNFRIEND = 'UNFRIEND',
}

export enum RedditSettingKey {
  CONTENT_VISIBILITY = 'CONTENT_VISIBILITY',
  COMMUNITY_VISIBILITY = 'COMMUNITY_VISIBILITY',
  CHAT_REQUESTS = 'CHAT_REQUESTS',
  DELETE_POSTS = 'DELETE_POSTS',
  DELETE_COMMENTS = 'DELETE_COMMENTS',
  DISCOVER_PROFILE = 'DISCOVER_PROFILE',
  MESSAGE_REQUESTS = 'MESSAGE_REQUESTS',
  ONLINE_STATUS = 'ONLINE_STATUS',
  PERSONALIZE_ADS = 'PERSONALIZE_ADS',
  NOTIFICATION_PRIVATE_MESSAGES = 'NOTIFICATION_PRIVATE_MESSAGES',
  NOTIFICATION_CHAT_MESSAGES = 'NOTIFICATION_CHAT_MESSAGES',
  NOTIFICATION_CHAT_REQUESTS = 'NOTIFICATION_CHAT_REQUESTS',
  NOTIFICATION_MENTIONS = 'NOTIFICATION_MENTIONS',
  NOTIFICATION_COMMENTS = 'NOTIFICATION_COMMENTS',
  NOTIFICATION_UPVOTES = 'NOTIFICATION_UPVOTES',
  NOTIFICATION_COMMENT_UPVOTES = 'NOTIFICATION_COMMENT_UPVOTES',
  NOTIFICATION_COMMENT_REPLIES = 'NOTIFICATION_COMMENT_REPLIES',
  NOTIFICATION_FOLLOWERS = 'NOTIFICATION_FOLLOWERS',
  NOTIFICATION_AWARDS = 'NOTIFICATION_AWARDS',
  NOTIFICATION_POST_FOLLOW = 'NOTIFICATION_POST_FOLLOW',
  NOTIFICATION_COMMENT_FOLLOW = 'NOTIFICATION_COMMENT_FOLLOW',
  NOTIFICATION_ACHIEVEMENT = 'NOTIFICATION_ACHIEVEMENT',
  NOTIFICATION_REMINDER = 'NOTIFICATION_REMINDER',
  NOTIFICATION_POST_INSIGHTS = 'NOTIFICATION_POST_INSIGHTS',
  NOTIFICATION_TRENDING_POSTS = 'NOTIFICATION_TRENDING_POSTS',
  NOTIFICATION_COMMUNITY_RECOMMENDATIONS = 'NOTIFICATION_COMMUNITY_RECOMMENDATIONS',
  NOTIFICATION_REREDDIT = 'NOTIFICATION_REREDDIT',
  NOTIFICATION_FEATURED_CONTENT = 'NOTIFICATION_FEATURED_CONTENT',
  NOTIFICATION_ANNOUNCEMENTS = 'NOTIFICATION_ANNOUNCEMENTS',
  NOTIFICATION_CAKE_DAY = 'NOTIFICATION_CAKE_DAY',
  NOTIFICATION_MOD_NOTIFICATIONS = 'NOTIFICATION_MOD_NOTIFICATIONS',
  EMAIL_NOTIFICATION_PRIVATE_MESSAGES = 'EMAIL_NOTIFICATION_PRIVATE_MESSAGES',
  EMAIL_NOTIFICATION_CHAT_REQUESTS = 'EMAIL_NOTIFICATION_CHAT_REQUESTS',
  EMAIL_NOTIFICATION_COMMENTS = 'EMAIL_NOTIFICATION_COMMENTS',
  EMAIL_NOTIFICATION_REPLIES = 'EMAIL_NOTIFICATION_REPLIES',
  EMAIL_NOTIFICATION_UPVOTES = 'EMAIL_NOTIFICATION_UPVOTES',
  EMAIL_NOTIFICATION_COMMENT_UPVOTES = 'EMAIL_NOTIFICATION_COMMENT_UPVOTES',
  EMAIL_NOTIFICATION_MENTIONS = 'EMAIL_NOTIFICATION_MENTIONS',
  EMAIL_NOTIFICATION_FOLLOWERS = 'EMAIL_NOTIFICATION_FOLLOWERS',
  EMAIL_NOTIFICATION_DAILY_DIGEST = 'EMAIL_NOTIFICATION_DAILY_DIGEST',
  TWO_FACTOR_AUTHENTICATION = 'TWO_FACTOR_AUTHENTICATION',
}

export enum YoutubeSettingKey {
  PUSH_NOTIFICATION_SUBSCRIPTIONS = 'PUSH_NOTIFICATION_SUBSCRIPTIONS',
  PUSH_NOTIFICATION_RECOMMENDED_VIDEOS = 'PUSH_NOTIFICATION_RECOMMENDED_VIDEOS',
  PUSH_NOTIFICATION_CHANNEL_ACTIVITY = 'PUSH_NOTIFICATION_CHANNEL_ACTIVITY',
  PUSH_NOTIFICATION_COMMENT_REPLIES = 'PUSH_NOTIFICATION_COMMENT_REPLIES',
  PUSH_NOTIFICATION_MENTIONS = 'PUSH_NOTIFICATION_MENTIONS',
  PUSH_NOTIFICATION_SHARED_CONTENT = 'PUSH_NOTIFICATION_SHARED_CONTENT',
  PUSH_NOTIFICATION_PROMOTIONAL_CONTENT = 'PUSH_NOTIFICATION_PROMOTIONAL_CONTENT',
  EMAIL_NOTIFICATIONS = 'EMAIL_NOTIFICATIONS',
  SUBSCRIPTION_PRIVACY = 'SUBSCRIPTION_PRIVACY',
  QUICK_PURCHASE = 'QUICK_PURCHASE',
}

export enum GoogleSettingKey {
  // GOOGLE_2FA
  TWOFA = 'TWOFA',
  // GOOGLE_PROFILE_INFO
  BIRTHDAY = 'BIRTHDAY',
  GENDER = 'GENDER',
  // GOOGLE_ACTIVITY_CONTROLS
  WEB_APP_ACTIVITY = 'WEB_APP_ACTIVITY',
  TIMELINE = 'TIMELINE',
  YOUTUBE_HISTORY = 'YOUTUBE_HISTORY',
  PERSONALIZED_ADS = 'PERSONALIZED_ADS',
  // GOOGLE_SHARED_ENDORSEMENTS
  SHARED_ENDORSEMENTS = 'SHARED_ENDORSEMENTS',
  // GOOGLE_PAYMENTS
  PAYMENTS_SHARING = 'PAYMENTS_SHARING',
  CREDITWORTHINESS_SHARING = 'CREDITWORTHINESS_SHARING',
  MARKETING_SHARING = 'MARKETING_SHARING',
  PERSONALIZATION = 'PERSONALIZATION',
  // GOOGLE_MAPS_PROFILE_PRIVACY
  RESTRICTED_PROFILE = 'RESTRICTED_PROFILE',
  SHOW_POSTS_PUBLIC_PROFILE = 'SHOW_POSTS_PUBLIC_PROFILE',
}

const settingKeyEnums = [
  StravaSettingKey,
  VenmoSettingKey,
  XSettingKey,
  LinkedinSettingKey,
  InstagramSettingKey,
  FacebookSettingKey,
  RedditSettingKey,
  YoutubeSettingKey,
  GoogleSettingKey,
]

export const isValidSettingKey = (keyString: string): keyString is SettingKey =>
  settingKeyEnums.some((settingKeyEnum) =>
    Object.keys(settingKeyEnum).includes(keyString),
  )
