import { EXTENSION_MESSAGE_TYPES } from './enums'
import {
  getSentryInitialized,
  logSentryException,
  logSentryMessage,
} from './logging'
import * as messages from './messages'

async function logErrorEvent(error, entryPoint) {
  const message = messages.createMessage(
    EXTENSION_MESSAGE_TYPES.LOG_ERROR_MSG,
    {
      error,
      entryPoint,
    },
  )

  const response = await chrome.runtime.sendMessage(message)

  return response
}

async function logInfoEvent(info, entryPoint) {
  const message = messages.createMessage(EXTENSION_MESSAGE_TYPES.LOG_INFO_MSG, {
    info,
    entryPoint,
  })

  const response = await chrome.runtime.sendMessage(message)

  return response
}

export function logError(error: Error, entryPointTag: string) {
  if (getSentryInitialized()) {
    logSentryException(error, entryPointTag)
  } else {
    logErrorEvent(error, entryPointTag)
  }
}

export function logInfo(message: string, entryPointTag: string) {
  if (getSentryInitialized()) {
    logSentryMessage(message, entryPointTag)
  } else {
    logInfoEvent(message, entryPointTag)
  }
}
