import React, { MouseEventHandler, useCallback, useMemo } from 'react'

import clsx from 'clsx'

import { State } from '$extensionSrc/automations/facebook/fbUnfriend'
import { TaskButton } from '$extensionSrc/components/Buttons'
import ProtipBanner from '$extensionSrc/components/Sidebar/RecommendationCards/RecommendationCardContainer/RecommendationCard/ProtipBanner'
import { AccessLevel } from '$extensionSrc/data/user/accessByPlatform'
import FbUnfriend from '$extensionSrc/images/fb-unfriend.svg'
import { startGather, useFbUnfriend } from '$extensionSrc/utils/fbUnfriend'

import styles from './FbUnfriendBody.module.scss'
import FriendSets from './FriendSets'
import UnfriendingCount from './UnfriendingCount'

export default function FbUnfriendBody({
  accessLevel,
  handleShowUpsell,
  languageLocked,
  platformUserId,
  unfriendState,
}: {
  accessLevel: AccessLevel
  handleShowUpsell: () => void
  languageLocked: boolean
  platformUserId: string
  unfriendState: State | null
}) {
  const { allFriendsAreLoaded, openModal } = useFbUnfriend(platformUserId)

  const isPreGather = useMemo(
    () => !unfriendState?.batches || unfriendState.batches.length === 0,
    [unfriendState],
  )

  const handleStartReviewingButtonClick = useCallback(() => {
    if (accessLevel === AccessLevel.PREVIEW) {
      handleShowUpsell()
    } else {
      startGather(platformUserId)
    }
  }, [accessLevel, handleShowUpsell, platformUserId])

  const handleLoadAllFriendsClick: MouseEventHandler<HTMLButtonElement> =
    useCallback(() => {
      startGather(platformUserId, true)
    }, [platformUserId])

  const handleBatchSelected = useCallback(
    (index: number) => openModal(index),
    [openModal],
  )

  return (
    <div className={styles.fbUnfriendBody}>
      <div className={styles.recommendationBody}>
        <ProtipBanner variant="blue" iconType="people">
          When you have many Facebook friends, reviewing in{' '}
          <strong>sets of 100</strong> helps make cleanup manageable
        </ProtipBanner>
        {isPreGather && (
          <>
            <div className={styles['recommendation-body-title']}>
              <div className="body1 black bold">
                Multi-select makes cleanup easy:
              </div>
            </div>
            <FbUnfriend />
          </>
        )}
        {!isPreGather && (
          <div className={styles['recommendation-body-title']}>
            <div className="body1 black bold">
              Review and clean up your friends list:
            </div>
            <FriendSets
              allFriendsAreLoaded={allFriendsAreLoaded}
              onBatchSelected={handleBatchSelected}
              onLoadAllFriendsClick={handleLoadAllFriendsClick}
              state={unfriendState}
            />
          </div>
        )}
      </div>
      <div className={styles.recommendationFooter}>
        {isPreGather && (
          <>
            <div className={styles['footer-buttons']}>
              <TaskButton
                buttonText="Start reviewing"
                disabled={languageLocked}
                onClick={handleStartReviewingButtonClick}
                preview={accessLevel === AccessLevel.PREVIEW}
              />
            </div>
            {languageLocked && (
              <span
                aria-hidden
                className={clsx(styles['tooltip-text'], 'body3')}
              >
                To use this feature, switch your language settings on Facebook
                to US English
              </span>
            )}
          </>
        )}
        {!isPreGather && (
          <UnfriendingCount unfriended={unfriendState?.unfriended || {}} />
        )}
      </div>
    </div>
  )
}
