import React from 'react'

import { useRequest } from 'ahooks'
import clsx from 'clsx'

import { dayInMs } from '$extensionSrc/utils/dateUtils'

import styles from './MetricsKpis.module.scss'
import { getIsoDateString } from '../utils/dateHelpers'
import {
  getBulkRequestUrlForKey,
  ResponseJsonType,
} from '../utils/metricsApiRequests'

type MetricsBoxInput = {
  title: string
  value: number
  lastUpdatedOn: string
}

function MetricsBox({ title, value, lastUpdatedOn }: MetricsBoxInput) {
  const olderThanOneDay =
    new Date().setHours(0, 0, 0, 0) -
      new Date(lastUpdatedOn).setHours(0, 0, 0, 0) >
    dayInMs

  return (
    <div className={styles.MetricsBox}>
      <h2>{title}</h2>
      <div>
        <span className={clsx('display2', olderThanOneDay && 'red')}>
          <span className="white">{olderThanOneDay && '*'}</span>
          {value}
          <span>{olderThanOneDay && '*'}</span>
        </span>
      </div>
      <div className={clsx('body3', olderThanOneDay && 'red')}>
        Last updated on: <br />{' '}
        {lastUpdatedOn ? getIsoDateString(new Date(lastUpdatedOn)) : '(null)'}
      </div>
    </div>
  )
}

type RateBoxInput = {
  title: string
  overallValue: number
  monthlyLabels: string[]
  monthlyValues: number[]
  lastUpdatedOn: string
}

function RateBox({
  title,
  overallValue,
  monthlyLabels,
  monthlyValues,
  lastUpdatedOn,
}: RateBoxInput) {
  const olderThanOneDay =
    new Date().setHours(0, 0, 0, 0) -
      new Date(lastUpdatedOn).setHours(0, 0, 0, 0) >
    dayInMs

  const asPercent = (value: number) => value && `${(value * 100).toFixed(2)}%`

  const ixes = Math.min(monthlyLabels.length, monthlyValues.length)

  // Convert Sat, 01 Feb 2025 00:00:00 GMT => Feb 2025
  function fmtUTCDateString(dateString: string) {
    return dateString.split(' ').slice(2, 4).join(' ')
  }

  return (
    <div className={styles.RateBox}>
      <h2>{title}</h2>
      <div>
        <span className={clsx('display2', olderThanOneDay && 'red')}>
          <span className="white">{olderThanOneDay && '*'}</span>
          {asPercent(overallValue)}
          <span>{olderThanOneDay && '*'}</span>
        </span>
      </div>
      <div className={clsx('body2', 'center', olderThanOneDay && 'red')}>
        {[...Array(ixes).keys()].map((ix) => {
          const label = monthlyLabels[ix]
          const value = monthlyValues[ix]

          return (
            <div key={`data-for-${label}`}>
              {fmtUTCDateString(label)}: {asPercent(value)}
            </div>
          )
        })}
      </div>
      <div className={clsx('body3', olderThanOneDay && 'red')}>
        Last updated on:{' '}
        {lastUpdatedOn ? getIsoDateString(new Date(lastUpdatedOn)) : '(null)'}
      </div>
    </div>
  )
}

export default function MetricsKpis() {
  const dataKeys = [
    'installs_chrome',
    'installs_firefox',
    'installs_edge',
    'n_signups_completed',
    'n_trials_created',
    'n_plans_created',
    'm1_retention_overall',
    'm1_retention_month_by_month',
    'signup_page_conversion_overall',
    'signup_page_conversion_month_by_month',
  ]

  const { data, loading } = useRequest(async () => {
    const response = await fetch(
      getBulkRequestUrlForKey(dataKeys, {
        startDateAsStr: '2023-01-01',
        endDateAsStr: getIsoDateString(new Date()),
      }),
    )

    if (response.status === 200) {
      const tsdata: ResponseJsonType = await response.json()

      const raw: { [key: string]: { [key: string]: number } } = {}
      const totals: { [key: string]: number } = {}
      for (const key of dataKeys) {
        raw[key] = Object.fromEntries(
          tsdata[key].timeseries.map(({ date, value }) => [date, value]),
        )
        totals[key] = tsdata[key].timeseries.reduce(
          (acc, { value }) => acc + value,
          0,
        )
      }

      const lastUpdatedOn: { [key: string]: string } = {}
      for (const key of dataKeys) {
        lastUpdatedOn[key] = tsdata[key].lastUpdatedOn
      }

      return { raw, totals, lastUpdatedOn }
    }
    throw new Error(`Failed to fetch data: ${response.statusText}`)
  })

  if (loading) {
    return <div>Loading...</div>
  }

  const totalInstalls =
    (data?.totals.installs_chrome ?? 0) +
    (data?.totals.installs_firefox ?? 0) +
    (data?.totals.installs_edge ?? 0)

  const inputs: MetricsBoxInput[] = [
    {
      title: 'Total Installs',
      value: totalInstalls,
      lastUpdatedOn: data?.lastUpdatedOn.installs_chrome,
    },
    {
      title: 'Signups',
      value: data?.totals.n_signups_completed,
      lastUpdatedOn: data?.lastUpdatedOn.n_signups_completed,
    },
    {
      title: 'Trials',
      value: data?.totals.n_trials_created,
      lastUpdatedOn: data?.lastUpdatedOn.n_trials_created,
    },
    {
      title: 'Plans',
      value: data?.totals.n_plans_created,
      lastUpdatedOn: data?.lastUpdatedOn.n_plans_created,
    },
  ]

  const getStartOfMonth = (date: Date) =>
    new Date(new Date(date.setUTCDate(1)).setUTCHours(0, 0, 0, 0))

  const getNthMonth = (n: number) =>
    getStartOfMonth(
      new Date(new Date().setUTCMonth(new Date().getUTCMonth() - n)),
    )
  const thisMonth = getNthMonth(0).toUTCString()
  const lastMonth = getNthMonth(1).toUTCString()
  const twoMonthsAgo = getNthMonth(2).toUTCString()
  const threeMonthsAgo = getNthMonth(3).toUTCString()

  const getLatest = (timeseries: { [dateString: string]: number }) => {
    const latest = Object.entries(timeseries).reduce(
      (acc, [dateString, value]) => {
        const date = new Date(dateString)
        if (date > acc.date) {
          return { date, value }
        }
        return acc
      },
      { date: new Date(0), value: 0 },
    )
    return latest.value
  }

  const rateInputs: RateBoxInput[] = [
    {
      title: 'M1 Retention',
      overallValue: getLatest(data?.raw.m1_retention_overall),
      monthlyLabels: [lastMonth, twoMonthsAgo, threeMonthsAgo],
      monthlyValues: [
        data?.raw.m1_retention_month_by_month[lastMonth],
        data?.raw.m1_retention_month_by_month[twoMonthsAgo],
        data?.raw.m1_retention_month_by_month[threeMonthsAgo],
      ],
      lastUpdatedOn: data?.lastUpdatedOn.m1_retention_overall,
    },
    {
      title: 'Signup Page Conversion',
      overallValue: getLatest(data?.raw.signup_page_conversion_overall),
      monthlyLabels: [thisMonth, lastMonth, twoMonthsAgo],
      monthlyValues: [
        data?.raw.signup_page_conversion_month_by_month[thisMonth],
        data?.raw.signup_page_conversion_month_by_month[lastMonth],
        data?.raw.signup_page_conversion_month_by_month[twoMonthsAgo],
      ],
      lastUpdatedOn: data?.lastUpdatedOn.signup_page_conversion_overall,
    },
  ]

  return (
    <div className={styles.MetricsKpis}>
      <h1>KPIs</h1>
      <h3 className={styles.subheader}>Aggregate totals (since 2023.01.01)</h3>
      <div className={styles.stats}>
        {inputs.map((input) => (
          <MetricsBox key={input.title} {...input} />
        ))}
      </div>
      <h3 className={styles.subheader}>User rate metrics (rolling 3 months)</h3>
      <div className={styles.stats}>
        {rateInputs.map((input) => (
          <RateBox key={input.title} {...input} />
        ))}
      </div>
    </div>
  )
}
