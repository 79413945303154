/**
 * Active Tab ID
 *
 * This file contains utilities for storing and retrieving the active tab ID for
 * each platform.
 *
 * We use the active tab ID so that we know which tab is currently running an
 * automation and which ones are not; please not that this "active" terminology
 * is distinct from what the browser might consider "active".
 *
 * This is stored in extension local storage at unique keys for each platform; we
 * clear the keys when there is no longer an active tab.
 *
 * This code is frequently used via the messaging logic; this is because the
 * chrome.tabs API is not available to content-scripts.
 *
 * Note that if you want to access the active tab from the background script,
 * you should directly use these methods (you can't send messages from the
 * background script to itself).
 */
import { PLATFORM, STORAGE_KEY } from '$extensionSrc/utils/enums'
import * as ls from '$extensionSrc/utils/localStorage'

export function getActiveTabStorageKeyForPlatform(platform: PLATFORM): string {
  const keyMap: Record<PLATFORM, string> = {
    [PLATFORM.FACEBOOK]: STORAGE_KEY.FACEBOOK_ACTIVE_TAB_ID,
    [PLATFORM.GOOGLE]: STORAGE_KEY.GOOGLE_ACTIVE_TAB_ID,
    [PLATFORM.INSTAGRAM]: STORAGE_KEY.INSTAGRAM_ACTIVE_TAB_ID,
    [PLATFORM.LINKEDIN]: STORAGE_KEY.LINKEDIN_ACTIVE_TAB_ID,
    [PLATFORM.REDDIT]: STORAGE_KEY.REDDIT_ACTIVE_TAB_ID,
    [PLATFORM.SNAPCHAT]: STORAGE_KEY.SNAPCHAT_ACTIVE_TAB_ID,
    [PLATFORM.STRAVA]: STORAGE_KEY.STRAVA_ACTIVE_TAB_ID,
    [PLATFORM.TIKTOK]: STORAGE_KEY.TIKTOK_ACTIVE_TAB_ID,
    [PLATFORM.TWITTER]: STORAGE_KEY.TWITTER_ACTIVE_TAB_ID,
    [PLATFORM.VENMO]: STORAGE_KEY.VENMO_ACTIVE_TAB_ID,
    [PLATFORM.YOUTUBE]: STORAGE_KEY.YOUTUBE_ACTIVE_TAB_ID,
  }

  if (!(platform in keyMap)) {
    throw new Error(`Invalid platform ${platform}`)
  }

  return keyMap[platform]
}

export async function getActiveTabId(platform: PLATFORM): Promise<number> {
  const activeTabStorageKey = getActiveTabStorageKeyForPlatform(platform)
  const activeTabId = await ls.getSingle(activeTabStorageKey)
  return activeTabId
}

export async function getIsActiveTabStillOpen(
  platform: PLATFORM,
): Promise<boolean | undefined> {
  const activeTabId = await getActiveTabId(platform)

  if (!activeTabId) {
    return undefined
  }

  return Boolean(await chrome.tabs.get(activeTabId).catch(() => null))
}

export async function setActiveTabId(
  platform: PLATFORM,
  tabId: number,
): Promise<void> {
  const activeTabStorageKey = getActiveTabStorageKeyForPlatform(platform)
  await ls.set({ [activeTabStorageKey]: tabId })
}

export async function clearActiveTabId(platform: PLATFORM): Promise<void> {
  const activeTabStorageKey = getActiveTabStorageKeyForPlatform(platform)
  await ls.remove(activeTabStorageKey)
}
