import { StoredRecommendationProgress } from '$extensionSrc/recommendations/recommendationProgressTypes'
import { getRecommendationKeysToUseForPlatform } from '$extensionSrc/recommendations/recommendationRegistryApi'
import { STORAGE_KEY, PLATFORM } from '$extensionSrc/utils/enums'
import * as localStorage from '$extensionSrc/utils/localStorage'

export default async function isRescan(
  platform: PLATFORM,
  platformUserId: string,
): Promise<boolean> {
  const ls = await localStorage.get([
    STORAGE_KEY.ACCOUNT_DATA,
    STORAGE_KEY.DEBUG_DATA,
    STORAGE_KEY.RECC_PROGRESS,
  ])

  if (!ls || !ls[STORAGE_KEY.ACCOUNT_DATA] || !ls[STORAGE_KEY.RECC_PROGRESS]) {
    return false
  }

  const platformRecommendationKeys = getRecommendationKeysToUseForPlatform(
    platform,
    ls[STORAGE_KEY.ACCOUNT_DATA],
    ls[STORAGE_KEY.DEBUG_DATA],
  )

  let isRescanAutomation = false
  for (const k of platformRecommendationKeys) {
    const progress: StoredRecommendationProgress | undefined =
      ls[STORAGE_KEY.RECC_PROGRESS]?.[platform]?.[platformUserId]?.[k]
    if (progress?.lastGatheredTimestamp !== null) {
      isRescanAutomation = true
    }
  }

  return isRescanAutomation
}
