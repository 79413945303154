import { useCallback, useMemo } from 'react'

import set from 'lodash/set'
import unset from 'lodash/unset'

import { getActiveTabStorageKeyForPlatform } from '$extensionSrc/data/activeTabId'
import { FacebookSettingKey } from '$extensionSrc/recommendations/recommendationSettingsKeys'
import { updateRecommendation } from '$extensionSrc/state'

import * as communication from './communication'
import {
  AutomationType,
  FBRecommendationKey,
  FullScreenModalType,
  PLATFORM,
  STORAGE_KEY,
} from './enums'
import * as localStorage from './localStorage'
import { startAutomationMachineForRecommendation } from '../automations/automationStageMachine'
import type {
  Batch,
  Friend,
  FriendsLookup,
  GatherInputData,
  State,
} from '../automations/facebook/fbUnfriend'
import { useExtensionData } from '../hooks/extensionStorageListener'

async function setUnfriendState(state: State, platformUserId: string) {
  await updateRecommendation(
    PLATFORM.FACEBOOK,
    platformUserId,
    FBRecommendationKey.FB_UNFRIEND,
    {
      initialState: { [FacebookSettingKey.UNFRIEND]: state },
    },
  )
}

async function setAllIsSelected(
  state: State,
  platformUserId: string,
  batchIndex: number,
  value: boolean,
) {
  const friends: Friend[] = state.batches[batchIndex].friends.map(
    (f: Friend) => ({
      ...f,
      isSelected: value,
    }),
  )

  const newData = set(
    { ...state },
    ['batches', batchIndex, 'friends'].join('.'),
    friends,
  )

  await setUnfriendState(newData, platformUserId)
}

async function setIsSelected(
  state: State,
  platformUserId: string,
  batchIndex: number,
  friendId: string,
  value: boolean,
) {
  const friendIndex = state.batches[batchIndex].friends.findIndex(
    (f: Friend) => f.id === friendId,
  )
  const newData = set(
    { ...state },
    ['batches', batchIndex, 'friends', friendIndex, 'isSelected'].join('.'),
    value,
  )

  await setUnfriendState(newData, platformUserId)
}

async function setMarkAsReviewed(
  state: State,
  platformUserId: string,
  batchIndex: number,
  value: boolean,
) {
  const newData = set(
    { ...state },
    ['batches', batchIndex, 'reviewed'].join('.'),
    value,
  )
  await setUnfriendState(newData, platformUserId)
}

export async function openModal(
  index: number,
  platformUserId: string,
  unfriendedDuringMostRecentUpdate?: Record<string, true>,
): Promise<void> {
  await communication.sendSetActiveTabForPlatform(PLATFORM.FACEBOOK)
  const activeTabStorageKey = getActiveTabStorageKeyForPlatform(
    PLATFORM.FACEBOOK,
  )
  const activeTabId = await localStorage.getSingle(activeTabStorageKey)

  let toast: string | undefined
  if (
    unfriendedDuringMostRecentUpdate &&
    Object.keys(unfriendedDuringMostRecentUpdate).length > 0
  ) {
    toast =
      Object.keys(unfriendedDuringMostRecentUpdate).length === 1
        ? '1 profile was unfriended.'
        : `${Object.keys(unfriendedDuringMostRecentUpdate).length} profiles were unfriended.`
  }

  await localStorage.set({
    [STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL]: {
      [activeTabId]: {
        selectedModalType: FullScreenModalType.FB_UNFRIEND_MODAL,
        selectedModalData: {
          index,
          platform: PLATFORM.FACEBOOK,
          platformUserId,
          toast,
        },
      },
    },
  })
}

export async function removeToast(): Promise<void> {
  const data = await localStorage.get([STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL])
  const tabIds = Object.keys(
    data?.[STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL] || {},
  )
  const newData = { ...data }
  for (const tabId of tabIds) {
    unset(
      newData,
      [
        STORAGE_KEY.SELECTED_FULL_SCREEN_MODAL,
        tabId,
        'selectedModalData',
        'toast',
      ].join('.'),
    )
  }
  await localStorage.set(newData)
}

export async function startGather(
  platformUserId: string,
  loadAllFriendsWasClicked?: boolean,
  openSet4?: boolean,
): Promise<void> {
  await communication.sendSetActiveTabForPlatform(PLATFORM.FACEBOOK)
  let inputData: GatherInputData | null = null
  if (loadAllFriendsWasClicked) {
    inputData = {
      ...(inputData || {}),
      loadAllFriendsWasClicked,
    }
  }
  if (openSet4) {
    inputData = {
      ...(inputData || {}),
      openSet4,
    }
  }
  await startAutomationMachineForRecommendation(
    PLATFORM.FACEBOOK,
    platformUserId,
    FBRecommendationKey.FB_UNFRIEND,
    AutomationType.GATHER,
    inputData,
  )
  if (openSet4) {
    await communication.sendCloseModalOnCurrentTab()
  }
}

export async function startUpdate(
  platformUserId: string,
  index: number,
  selected: FriendsLookup,
): Promise<void> {
  await communication.sendSetActiveTabForPlatform(PLATFORM.FACEBOOK)
  const inputData = { index, selected }
  await startAutomationMachineForRecommendation(
    PLATFORM.FACEBOOK,
    platformUserId,
    FBRecommendationKey.FB_UNFRIEND,
    AutomationType.UPDATE,
    inputData,
  )
  await communication.sendCloseModalOnCurrentTab()
}

export function useFbUnfriend(platformUserId: string): {
  allFriendsAreLoaded: boolean
  batches: Batch[]
  onMarkAsReviewed: (index: number) => Promise<void>
  onSelect: (index: number, friendId: string) => Promise<void>
  onSelectAll: (index: number) => Promise<void>
  onUnmarkAsReviewed: (index: number) => Promise<void>
  onUnselect: (index: number, friendId: string) => Promise<void>
  onUnselectAll: (index: number) => Promise<void>
  openModal: (index: number, toast?: string) => Promise<void>
} {
  const { data, isLoaded } = useExtensionData({
    local: [STORAGE_KEY.RECC_PROGRESS],
  })

  const state = useMemo<State | undefined>(() => {
    if (!isLoaded) {
      return undefined
    }

    const initialState =
      data?.[STORAGE_KEY.RECC_PROGRESS]?.[PLATFORM.FACEBOOK]?.[
        platformUserId
      ]?.[FBRecommendationKey.FB_UNFRIEND]?.initialState

    // March 2025: Migration code to read initial state after adding SettingKey
    // UNFRIEND instead of initial state as a flat object.
    let unfriendState = initialState
    if (
      initialState &&
      Object.keys(initialState)?.length === 1 &&
      Object.keys(initialState)[0] === FacebookSettingKey.UNFRIEND
    ) {
      // Initial State migrated to always use setting keys
      unfriendState = Object.values(initialState)[0]
    }
    return unfriendState
  }, [data, isLoaded, platformUserId])

  const batches = useMemo<Batch[]>(() => {
    if (!isLoaded) {
      return []
    }
    return state?.batches || []
  }, [isLoaded, state])

  const allFriendsAreLoaded: boolean = useMemo(() => {
    if (!isLoaded) {
      return false
    }

    // The state.remaining <= 0 condition is a fail safe that prevents
    // a negative remaining number from translating to more friends needing
    // to be loaded in the case that the numberes in state get out of sync
    return state?.labeledTotal !== undefined && state?.remaining <= 0
  }, [isLoaded, state])

  const handleMarkAsReviewed = useCallback(
    async (index: number) => {
      setAllIsSelected(state, platformUserId, index, false)
      setMarkAsReviewed(state, platformUserId, index, true)
    },
    [state, platformUserId],
  )

  const handleSelect = useCallback(
    async (index: number, friendId: string) => {
      setIsSelected(state, platformUserId, index, friendId, true)
    },
    [state, platformUserId],
  )

  const handleSelectAll = useCallback(
    async (index: number) => {
      setAllIsSelected(state, platformUserId, index, true)
    },
    [state, platformUserId],
  )

  const handleUnmarkAsReviewed = useCallback(
    async (index: number) => {
      setMarkAsReviewed(state, platformUserId, index, false)
    },
    [state, platformUserId],
  )

  const handleUnselect = useCallback(
    async (index: number, friendId: string) => {
      setIsSelected(state, platformUserId, index, friendId, false)
    },
    [state, platformUserId],
  )

  const handleUnselectAll = useCallback(
    async (index: number) => {
      setAllIsSelected(state, platformUserId, index, false)
    },
    [state, platformUserId],
  )

  const openModal_ = useCallback(
    async (index: number) => {
      openModal(index, platformUserId)
    },
    [platformUserId],
  )

  return {
    allFriendsAreLoaded,
    batches,
    onMarkAsReviewed: handleMarkAsReviewed,
    onSelect: handleSelect,
    onSelectAll: handleSelectAll,
    onUnmarkAsReviewed: handleUnmarkAsReviewed,
    onUnselect: handleUnselect,
    onUnselectAll: handleUnselectAll,
    openModal: openModal_,
  }
}
