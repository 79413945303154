import React from 'react'

import { createRoot } from 'react-dom/client'

const snakeToCamel = (key: string): string =>
  key
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace('-', '').replace('_', ''),
    )

export const addComponentToDOM = (
  Component: React.ComponentType,
  element: HTMLElement,
) => {
  if (!element) {
    return
  }
  let props = {}
  if (element.dataset.props) {
    try {
      props = JSON.parse(element.dataset.props)
    } catch {
      // No props
    }
  }
  Object.keys(props).forEach((snakeKey) => {
    const camelKey = snakeToCamel(snakeKey)
    props[camelKey] = props[snakeKey]
    if (camelKey !== snakeKey) {
      delete props[snakeKey]
    }
  })
  const elementRoot = createRoot(element)
  elementRoot.render(<Component {...props} />)

  // Remove props from element so don't get shown in dev console
  // eslint-disable-next-line no-param-reassign
  delete element.dataset.props
}

export const addComponentToDOMById = (
  Component: React.ComponentType,
  id: string,
) => {
  const element = document.getElementById(id)
  if (element) {
    addComponentToDOM(Component, element)
  }
}
