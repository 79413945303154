import {
  AccountTypeKey,
  InstagramAccountType,
  InstagramAccountVisibility,
} from './accountEnums'

export function getInstagramAccountTypeFilter(
  specs,
  accountData,
): (key: string) => boolean {
  return (key: string) => {
    const spec = specs[key]
    const validAccountTypes = spec.accountTypes

    if (!validAccountTypes) {
      return true
    }

    const accountType = accountData?.[AccountTypeKey.ACCOUNT_TYPE]
    const accountVisibility = accountData?.[AccountTypeKey.ACCOUNT_VISIBILITY]

    if (InstagramAccountType.PROFESSIONAL === accountType) {
      return validAccountTypes.includes('Page')
    }
    if (InstagramAccountVisibility.PRIVATE === accountVisibility) {
      return validAccountTypes.includes('Private')
    }
    return validAccountTypes.includes('Public')
  }
}
