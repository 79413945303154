import React from 'react'

import { useQuery } from '@apollo/client'

import { ImpactMetricsDebugDocument } from '../__generated__/graphql'
import { client } from '../apollo'

import './ImpactMetricsDebug.scss'

function UserIdInput() {
  const onSubmit = (e) => {
    e.preventDefault()

    const form = e.target
    const formData = new FormData(form)

    const newUrl = new URL(window.location.href)
    newUrl.searchParams.set('u', formData.get('userId').toString())

    window.location.assign(newUrl.href)
  }

  return (
    <form onSubmit={onSubmit}>
      <label htmlFor="userId">
        User ID: <input id="userId" name="userId" type="text" />
      </label>
      <button type="submit">Refresh</button>
    </form>
  )
}

export default function ImpactMetricsDebug({
  userId,
}: {
  userId: number | null
}) {
  const { data, loading } = useQuery(ImpactMetricsDebugDocument, {
    variables: {
      userId,
    },
    client,
  })

  if (loading) {
    return <div>Loading...</div>
  }

  const keyScores = JSON.parse(data?.impactMetricsDebug?.keyScores || '{}')
  const keyScoresStatus = JSON.parse(
    data?.impactMetricsDebug?.keyScoresStatus || '{}',
  )
  const checklistSummaries = JSON.parse(
    data?.impactMetricsDebug?.checklistSummaries || '{}',
  )
  const subcategoryScores = JSON.parse(
    data?.impactMetricsDebug?.subcategoryScores || '{}',
  )
  const highlights = JSON.parse(data?.impactMetricsDebug?.highlights || '{}')
  const todos = JSON.parse(data?.impactMetricsDebug?.todos || '{}')
  const accounts = JSON.parse(data?.impactMetricsDebug?.accounts || '[]')
  const autoscanSettings = JSON.parse(
    data?.impactMetricsDebug?.autoscanSettings || '[]',
  )
  const formattedProgress = JSON.parse(
    data?.impactMetricsDebug?.formattedProgress || '{}',
  )

  return (
    <div>
      <UserIdInput />
      {data?.impactMetricsDebug && (
        <>
          <h1>Overall Metrics</h1>
          <div>
            Overall Score:{' '}
            {data.impactMetricsDebug.overall
              ? data.impactMetricsDebug.overall * 100
              : null}
          </div>
          <div>Overall Status: {data.impactMetricsDebug.overallStatus}</div>
          <div>Time Saved: {data.impactMetricsDebug.timeSavedSec} seconds</div>
          <div>
            Last Updated:{' '}
            {new Date(data.impactMetricsDebug.lastUpdatedOn).toLocaleString()}
          </div>
          <hr />
          <h2>Metrics by Category</h2>
          {['Online Safety', 'Digital Wellbeing', 'Data Ownership'].map(
            (category) =>
              keyScores[category] !== undefined &&
              keyScores[category] !== null && (
                <div key={category}>
                  <h3>{category}</h3>
                  <div>Score: {keyScores[category] * 100}</div>
                  <div>Status: {keyScoresStatus[category]}</div>

                  {checklistSummaries[category] && (
                    <>
                      <h4>Checklist Summaries</h4>
                      <pre className="jsondump">
                        {JSON.stringify(checklistSummaries[category], null, 2)}
                      </pre>
                    </>
                  )}

                  {subcategoryScores[category] && (
                    <>
                      <h4>Subcategory Scores</h4>
                      <pre className="jsondump">
                        {JSON.stringify(subcategoryScores[category], null, 2)}
                      </pre>
                    </>
                  )}

                  {highlights[category] && (
                    <>
                      <h4>Highlights</h4>
                      <pre className="jsondump">
                        {JSON.stringify(highlights[category], null, 2)}
                      </pre>
                    </>
                  )}

                  {todos[category] && (
                    <>
                      <h4>Todos</h4>
                      <pre className="jsondump">
                        {JSON.stringify(todos[category], null, 2)}
                      </pre>
                    </>
                  )}

                  {formattedProgress[category] && (
                    <>
                      <h4>Progress</h4>
                      <div className="progress-table">
                        <table>
                          <thead>
                            <tr>
                              <th>Progress</th>
                              <th>Account</th>
                              <th>Rec Key</th>
                              <th>Status</th>
                              <th>Type</th>
                              <th>Last Updated</th>
                            </tr>
                          </thead>
                          <tbody>
                            {formattedProgress[category].map((p: any) => (
                              <tr
                                key={`
                                  ${p.platform}
                                  ${p.platform_account_id}
                                  ${p.recommendation_key}
                                `}
                              >
                                <td>{p.progress}</td>
                                <td>{p.platform_account_id}</td>
                                <td>{p.recommendation_key}</td>
                                <td className={p.status}>{p.status}</td>
                                <td className={p.type}>{p.type}</td>
                                <td>{p.last_updated_on}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  )}

                  <hr />
                </div>
              ),
          )}
          <h2>Account Information</h2>
          <h3>Connected Accounts</h3>
          <pre className="jsondump">{JSON.stringify(accounts, null, 2)}</pre>
          <h3>Autoscan Settings</h3>
          <pre className="jsondump">
            {JSON.stringify(autoscanSettings, null, 2)}
          </pre>
        </>
      )}
    </div>
  )
}
