import {
  ApolloClient,
  createHttpLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
  ServerError,
  ServerParseError,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import * as sentry from '@sentry/browser'

import BuildConfig from './buildConfig'
import { graphqlPath, graphqlUnauthPath, getApiUrl } from './consts/urlConsts'
import { refreshAccessToken } from './serverClient'
import {
  getHasValidAccessToken,
  getHasValidRefreshToken,
  getJwtCsrfHeader,
} from './utils/apiAuth'
import { logError } from './utils/loggingUtils'

const cache = new InMemoryCache()

export const isServerError = (
  error: Error | ServerParseError | ServerError | null,
): error is ServerError =>
  error !== null && (error as ServerError).statusCode !== undefined

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (
    networkError &&
    // @ts-ignore:next-line  (avoid annoying union type error)
    networkError?.statusCode !== 403 &&
    // @ts-ignore:next-line
    networkError?.statusCode !== 401
  ) {
    sentry.setTag('gqlOperationName', operation?.operationName)

    // Add additional information from graphQLErrors sentry, if available
    let errorMessage = networkError.message
    if (graphQLErrors && graphQLErrors.length > 0) {
      sentry.setTag('gqlErrors', graphQLErrors.map((x) => x.message).join(', '))
      errorMessage = `${errorMessage} - ${graphQLErrors[0].message}`
    }

    logError(new Error(errorMessage), 'apollo-client')
  }
})

const stagingHeaderLink = setContext(async (_operation, prevContext) => {
  let updatedHeaders: Record<string, string> = {
    ...(prevContext?.headers || {}),
  }

  if (BuildConfig.STAGING_BASIC_AUTH) {
    updatedHeaders = {
      ...updatedHeaders,
      Authorization: `Basic ${btoa(BuildConfig.STAGING_BASIC_AUTH)}`,
    }
  }

  return { headers: updatedHeaders }
})

const refreshTokenLink = setContext(async () => {
  const hasValidAccessToken = await getHasValidAccessToken()
  const hasValidRefreshToken = await getHasValidRefreshToken()

  if (!hasValidAccessToken && hasValidRefreshToken) {
    await refreshAccessToken()
  }
})

function getAuthLink() {
  const authHttpLink = createHttpLink({
    uri: getApiUrl(graphqlPath),
  })

  const authHeaderLink = setContext(async (_operation, prevContext) => {
    let updatedHeaders = {
      ...(prevContext?.headers || {}),
    }

    const [name, value] = await getJwtCsrfHeader('access')
    if (name && value) {
      updatedHeaders = {
        ...updatedHeaders,
        [name]: value,
      }
    }

    return { headers: updatedHeaders }
  })

  return from([
    refreshTokenLink,
    stagingHeaderLink,
    authHeaderLink,
    errorLink,
    authHttpLink,
  ])
}

function getUnauthLink() {
  const unauthHttpLink = createHttpLink({
    uri: getApiUrl(graphqlUnauthPath),
  })

  return from([stagingHeaderLink, errorLink, unauthHttpLink])
}

let client: ApolloClient<NormalizedCacheObject> | undefined

export function getClient() {
  if (client) {
    return client
  }

  client = new ApolloClient({
    cache,
    // In our case, we always want updated data, so we default to no-cache.
    // This means "fetch from server, and don't even bother to update the cache".
    defaultOptions: { query: { fetchPolicy: 'no-cache' } },
    link: getAuthLink(),
  })

  return client
}

let unauthClient: ApolloClient<NormalizedCacheObject> | undefined

export function getUnauthClient() {
  if (unauthClient) {
    return unauthClient
  }

  unauthClient = new ApolloClient({
    cache,
    // In our case, we always want updated data, so we default to no-cache.
    // This means "fetch from server, and don't even bother to update the cache".
    defaultOptions: { query: { fetchPolicy: 'no-cache' } },
    link: getUnauthLink(),
  })

  return unauthClient
}
